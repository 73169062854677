<template>
  <Headline></Headline>
  <div class="container-fluid" id="event">
    <div class="col col-sm-12 col-md-12 col-lg-12 col-xl-12" id="description">
      <!-- Smartphone -->
      <div class="container-fluid d-xs-block d-sm-block d-md-none">
        <div class="row row-cols-1 gx-1">
          <div class="col">
            <div class="number">{{ event.number }}</div>
            <div style="text-align: center;">
              <div class="title">{{ event.name }}</div>
              <div class="year">{{ event.time }}</div>
            </div>
            <audio controls>
              <source :src="event.audio" type="audio/mpeg">
            </audio>
            <p class="text">{{ event.description }}</p>
          </div>
          <div class="col">
            <div v-for="image in event.images" v-bind:key="image.id" v-on:click="lightbox.loadAndOpen(image.id)" class="image-container">
              <img :src="image.thumbnail" class="w-100" />
              <div class="caption">{{ '[' + (parseInt(image.id) + 1) + '] ' + image.title }}</div>
            </div>
          </div>
          
        </div>
      </div>
      <!-- Desktop -->
      <div class="container-fluid d-none d-md-block">
        <div class="row row-cols-2 gx-1">
          <div class="col-6">
            <div class="number">{{ event.number }}</div>
            <div class="title">{{ event.name }}</div>
            <div class="year">{{ event.time }}</div>
          </div>
          <div class="col-6">
            <audio controls style="margin-top: 5px; padding-left: 13px;">
                <source :src="event.audio" type="audio/mpeg">
            </audio>
          </div>
          <div class="col-12" style="padding-top: 15px; padding-bottom: 10px;">
            <p class="text" style="column-count:2; column-gap:30px;">
              {{ event.description }}
            </p>
          </div>
          <div class="col-12 images">
            <div class="row row-cols-4">
              <div class="col" v-for="image in event.images" v-bind:key="image.id" :title="image.title">
                <img v-on:click="lightbox.loadAndOpen(image.id)" :src="image.thumbnail" class="w-100"/>
                <div class="caption">{{ '[' + (parseInt(image.id) + 1) + '] ' + image.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-2 nav justify-content-between">
              <div class="col col-auto me-auto">
                <div class="button" v-show="event.number != 1" v-on:click="this.$router.push({name: this.$router.currentRoute.name, params: {id:getPreviousEventId(event)}})">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 -1 16 16" style="padding-bottom: 5px;">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
                  </svg>
                  <span>{{ parseInt(event.number) - 1 }}</span>
                </div>
              </div>
              <div class="col col-auto">
                <div class="button" v-show="event.number != 10" v-on:click="this.$router.push({name: this.$router.currentRoute.name, params: {id:getNextEventId(event)}})" style="text-align: right;" >
                  <span>{{ parseInt(event.number) + 1 }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 -1 16 16" style="padding-bottom: 5px;">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import Headline from "./Headline.vue";
import eventsData from "../assets/data/events.json";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeFullscreen from "../mixins/photoswipe-fullscreen.esm.min.js";
import "photoswipe/style.css";

export default {
  name: "eventView",
  components: {
    Headline
  },
  data() {
    return {
      event: this.getEvent(),
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        showHideAnimationType: "none",
        pswpModule: () => import("photoswipe"),
      });

      this.lightbox.addFilter("numItems", () => {
        return this.getEvent().images.length;
      });

      this.lightbox.addFilter("itemData", (data, index) => {
        var item = this.getEvent().images[index];
        return {
          src: item.src,
          width: item.w,
          height: item.h,
        };
      });

      this.lightbox.on('afterInit', () => {
        this.fullscreenPlugin.toggleFullscreen();
      });

      this.fullscreenPlugin = new PhotoSwipeFullscreen(this.lightbox);

      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {
    getPreviousEventId(event) {
      return '0' + (parseInt(event.number) - 1);
    },
    getNextEventId(event) {
      return parseInt(parseInt(event.number)) < 9 ? ('0' + (parseInt(event.number) + 1)) : '10';
    },
    getEvent() {
      return eventsData.filter(
        (event) => event.id === this.$route.params.id
      )[0];
    },
    getColumnItemsMap(numColumns) {
      var sumHeight = this.getEvent()
        .images.map((item) => item.h)
        .reduce((sum, current) => sum + current, 0);
      var heightPerColumn = sumHeight / numColumns;

      var result = {};
      var currentColumnIndex = 1;
      var currentColumnHeight = 0;
      this.getEvent().images.forEach((element, elementIndex) => {
        if (!result[currentColumnIndex]) {
          result[currentColumnIndex] = new Array();
        }
        element.index = elementIndex;
        result[currentColumnIndex].push(element);
        currentColumnHeight += element.h;
        if (
          currentColumnHeight > heightPerColumn &&
          currentColumnIndex < numColumns
        ) {
          currentColumnIndex += 1;
          currentColumnHeight = 0;
        }
      });

      return result;
    },
  },
};
</script>

<style scoped>

audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  width: 100%;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 8px;
}

#event {
  padding-left: 0px;
  padding-right: 0px !important;
  margin-right: 0px;
}

#event .text {
  text-align: justify;
  column-count: 1;
  font-size: 14pt;
  padding-right: 0px !important;
}

#event .text-2-columns {
  text-align: justify;
  column-count: 2;
  column-gap: 40px !important;
}

.images .container-fluid {
  padding: 10px 0 0 0;
}

.images img {
  cursor: pointer !important;
  padding-bottom: 8px;
}

.images img:hover {
  opacity: 0.6;
}

#event .title {
  text-align: center;
  font-family: "league_spartan_semi_bold";
  font-weight: 500;
  font-size: 1.25em;
  vertical-align: top;
  padding-left: 10px;
}

#event .number {
  border-radius: 5px;
  background-color: #aeca36;
  color: white;
  font-family: "league_spartan_semi_bold";
  font-weight: 400;
  text-align: center;
  font-size: 1.1em;
  padding: 7px;
  float: left;
  min-width: 36px;
  min-height: 55px;
}

#event .year {
  border-radius: 5px;
  font-family: "league_spartan_semi_bold";
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 1.1em;
  min-width: 35px;
}

#event .text {
  font-family: "league_spartan_regular";
  font-size: 1.2em;
  text-align: justify;
}

#event .image-container {
  padding-bottom: 5px !important;
}

#event .caption {
  padding-bottom: 10px;
  text-align: right;
  font-size: 1.0em;
  font-family: "league_spartan_regular";
}

#event .nav {
  padding-top: 20px;
}

#event .nav .button {
  display: block;
  border-radius: 5px;
  background-color: #aeca36;
  color: white;
  font-size: 1.1em;
  color: white;
  cursor: pointer;
  width: 60px;
}
</style>
