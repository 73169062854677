<template>
  <Headline title="125 Jahre Liebfrauenkirche" description=""></Headline>
  <div id="imprint">
    <h3>Impressum</h3>
    <p>Pfarrei St. Franziskus von Assisi</p>
    <p>Pfarrer Ralf Dunker</p>
    <p>Liebfrauenweg 2</p>
    <p>59063 Hamm</p>
    <p><a href="http://www.franziskus-hamm.de">http://www.franziskus-hamm.de</a></p>
    <br/>
    <h3>Kontakt</h3>
    <p>Telefon: +49 2381 50444</p>
    <p>Email: <a href="mailto:dunker@franziskus-hamm.de">dunker@franziskus-hamm.de</a></p>
    <br/>
    <br/>
    <h3>Quellenangaben</h3>
    <ol>
      <li>[1975] Stadt Hamm (Gerhard Krampe) - Die Liebfrauengemeinde in Hamm von 1899 bis 1945</li>
      <li>[1979] Katholisches Pfarramt Liebfrauen - Liebfrauengemeinde 1899-1979</li>
      <li>[1999] Katholische Kirchengemeinde Liebfrauen - 100 Jahre Liebfrauenkirche Hamm</li>
      <li>[2006] Pfarrbrief der Kirchengemeinde Liebfrauen Sommer 2006</li>
      <li>[2006] Ralf Dunker - Festschrift Orgel</li>
      <li>[2018] Pfarrei St. Franziskus von Assisi - Pastoralvereinbarung St. Franziskus von Assisi</li>
      <li>[2024] Pfarrei St. Franziskus von Assisi - Geschichtlicher Überblick Liebfrauen</li>
    </ol>
    <h4>Audio</h4>
    <p class="text" style="padding-bottom: 18px;">
      Die Audiodateien wurden mit einer KI-Software erstellt.
    </p>
    <h4>Texte</h4>
    <p class="text" style="padding-bottom: 18px;">Alle Texte basieren auf den Informationen aus den oben genannten Quellen und den Erinnerungen von Pfarrer Ralf Dunker.</p>
    <h4>Bildnachweise</h4>
    <ol>
      <li> Ereignis: Fotos 1,2,3 => Pfarrei St. Franziskus von Assisi [Quelle 1]</li>
      <li> Ereignis: Fotos 1,2,3 => Pfarrei St. Franziskus von Assisi [Quelle 1]</li>
      <li> Ereignis: Fotos 1,2,3 => Pfarrei St. Franziskus von Assisi [Quelle 2]</li>
      <li> Ereignis: Fotos 1 => Pfarrei St. Franziskus von Assisi [Quelle 1]; Foto 2 => Pfarrei St. Franziskus von Assisi [Quelle 2]</li>
      <li> Ereignis: Fotos 1,2,3 => Pfarrei St. Franziskus von Assisi [Quelle 2]; Foto 4 => Gerda Jucho [Quelle 1]</li>
      <li> Ereignis: Fotos 1,2,3 => Pfarrei St. Franziskus von Assisi [Quelle 1]</li>
      <li> Ereignis: Fotos 1,2,3,4 => Pfarrei St. Franziskus von Assisi [Quelle 3]</li>
      <li> Ereignis: Fotos 1,2 => S. Fix und A. Mehringskötter [privat]</li>
      <li> Ereignis: Fotos 1,2,3 => S. Fix und A. Mehringskötter [privat]</li>
      <li> Ereignis: Fotos 1,2 => Pfarrei St. Franziskus von Assisi</li>
    </ol>
  </div>
</template>

<script>
import Headline from "./Headline.vue";

export default {
  name: "ImprintView",
  components: {
    Headline,
  },
};
</script>

<style scoped>
#imprint {
  margin: 0 12px 0 12px;
}

#imprint h3 {
  font-family: "league_spartan_semi_bold";
  font-weight: 500;
  font-size: 1.2em;
}

#imprint h4 {
  font-family: "league_spartan_semi_bold";
  font-weight: 500;
  font-size: 1.1em;
  padding-top: 4px;
}

#imprint p {
  padding: 0px;
  margin: 0px;
  font-size: 1em;
}

#imprint div {
 font-size: 1.0em;
}

#imprint p,li,span {
  font-family: "league_spartan_regular";
  font-size: 1em;
}
#imprint .text {
  text-align: justify;
  padding-bottom: 4px;
}
</style>
