<template>
  <router-view :key="$route.fullPath" />
  <SiteFooter />
</template>

<script>
import SiteFooter from "./components/SiteFooter.vue";

export default {
  name: "App",
  components: {
    SiteFooter
  }
};
</script>

<style></style>
