import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Event from "@/components/Event.vue";
import Imprint from "@/components/Imprint.vue";

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: Home,
  },
  {
    path: "/events/:id",
    component: Event,
    name: 'events'
  },
  {
    path: "/imprint",
    component: Imprint,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes
});

export default router;
