<template>
  <footer id="footer">
    <div class="container-fluid d-xs-block d-sm-none">
      <span>&copy; Copyright 2024<br/>Pfarrei St. Franziskus von Assisi in Hamm<br/>Alle Rechte vorbehalten</span>
    </div>
    <div class="container-fluid d-none d-sm-block d-md-block d-lg-none">
      <span>&copy; Copyright 2024 - Pfarrei St. Franziskus von Assisi in Hamm - Alle Rechte vorbehalten</span>
    </div>
    <div class="container-fluid d-none d-lg-block d-xl-block">
      <span>&copy; Copyright 2024 - Pfarrei St. Franziskus von Assisi in Hamm - Alle Rechte vorbehalten</span>
    </div>
  </footer>
  <div class="impressum" v-on:click="this.$router.push('/imprint')">Impressum</div>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style scoped>
#footer {
  margin: 0 12px 0 12px;
}

#footer div {
  padding-top: 8px;
  padding-bottom: 6px;
  margin-top: 35px;
  margin-bottom: 20px;
  font-family: "league_spartan_light";
  font-size: 1.0em;
  color: white;
  text-align: center;
  border-radius: 5px;
  vertical-align: middle;
  background-color: #aeca36;
}

.impressum {
  font-size: 1em;
  width: 100%;
  text-align: center;
  font-family: "league_spartan_light";
  cursor: pointer;
}

</style>
