<template>
  <div class="container-fluid" id="headline">
    <div class="row row-cols-2">
      <div class="col-10" v-on:click="this.$router.push('/')">
        <h2>
          125 Jahre
          <br/>
          Liebfrauenkirche Hamm
        </h2>
      </div>
      <div class="col-2 home" v-on:click="this.$router.push('/')" title="zurück zur Startseite">
        <i class="bi bi-house" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HeadlineView"
};
</script>

<style scoped>
#headline {
  margin: 0 5x 0 5px;
}

#headline h2 {
  font-family: "league_spartan_semi_bold";
  font-size: 1.7em;
  letter-spacing: 2px;
  font-variant: small-caps;
}

#headline .row {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 30px;
}

#headline .row {
  text-align: left;
  cursor: pointer;
}

#headline .home {
  text-align: right;
  cursor: pointer;
  font-size: 1.5em;
}
</style>
