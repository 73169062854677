<template>
  <Headline></Headline>
  <div class="container-fluid" id="events">
    <p class="description">Die wichtigsten Ereignisse in der Geschichte der Liebfrauenkirche Hamm in Text und Bild</p>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 gx-3 gy-2">
      <div class="col" v-for="event in events" v-bind:key="event.id">
        <router-link :to="'/events/' + event.id">
          <div class="card" :to="'/events/' + event.id">
            <div class="card-body">
              <h5 class="card-text">
                <div class="number">{{ event.number }}</div>
                {{ event.name }}
              </h5>
            </div>
            <img :src="`${event.thumbnail}`" class="card-img-top" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from "./Headline.vue";
import eventsData from "../assets/data/events.json";

export default {
  name: "HomeView",
  components: {
    Headline,
  },
  data() {
    return {
      events: eventsData,
    };
  },
};
</script>

<style scoped>
#events .card {
  border: 0px;
  border-radius: 0;
  padding-bottom: 5px;
}

#events .card:hover .card-img-top {
  opacity: 0.6;
}

#events .card .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px !important;
}

#events .card .card-img-top {
  border-radius: 0px;
}

#events .card .card-text {
  text-align: left;
  font-family: "league_spartan_semi_bold";
  font-weight: 100;
  font-size: 1.15em;

}

#events .description {
  font-family: 'league_spartan_regular';
  font-size: 1.2em;
  text-align: justify;
}

.number {
  border-radius: 5px;
  background-color: #aeca36;
  padding: 5px 5px 3px 5px;
  color: white;
  font-family: "league_spartan_semi_bold";
  width: 30px;
  min-width: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 6px;
  font-size: 1.1em;
}
</style>